import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { fetchBDashboardDetail } from "../../Redux/thunks/DashoboardDetailThunk";

const DashboardBottom = () => {

    const dispatch = useDispatch<any>();
    const { DashboardDetail } = useSelector((state: RootState) => state.dashboardDetail);
    
    useEffect(()=>{
        dispatch(fetchBDashboardDetail());
    },[]);

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    
    const startDate = new Date(currentYear, currentMonth, 1);
    const endDate = new Date(currentYear, currentMonth + 1, 0);
    
    // Function to format the date
    const formatDate = (date : any) => {
      const day = date.getDate().toString().padStart(2, '0'); // Ensure 2 digits for day
      const month = date.toLocaleString('default', { month: 'short' }); // Get short month name
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    };
    
    return (
        <>
            <section className="mt-5 mb-20">
                <div className="container">
                    <div className="bg-white shadow-custom-shadow p-2 sm:p-5 rounded-lg">
                        <div className="flex items-center justify-between text-center">
                            <h4
                                className="text-xs sm:text-sm font-medium text-warning-color border-b-2 border-warning-color pb-2 w-[48%]"
                            >
                                My Account Overview
                            </h4>
                            <h4 className="text-xs sm:text-sm font-medium text-custom-text-color pb-2 w-[48%]">
                            My Biz Path
                            </h4>
                        </div>

                        <div className="mt-5">
                            <h5 className="text-xs sm:text-base font-medium text-custom-text-color text-center">
                            Current  Month  {formatDate(startDate)} - Ending  {formatDate(endDate)}
                            </h5>
                           
                           {
                            DashboardDetail ? (
                                  <>
                                  {DashboardDetail && (    
                                <div className="p-4 mb-4 text-center">
                                    <p className="text-lg mb-1 font-semibold">Accumulated LP</p>
                                    <p className="text-sm mb-4">{DashboardDetail.accu_left_node} | {DashboardDetail.accu_right_node}</p>
                                    <p className="text-lg mb-1 font-semibold">Today Sales</p>
                                    <p className="text-sm mb-4">{DashboardDetail.addition_left_node} | {DashboardDetail.addition_right_node}</p>
                                    <p className="text-lg mb-1 font-semibold">Today Matching</p>
                                    <p className="text-sm mb-4">{DashboardDetail.deduction_left_node} | {DashboardDetail.deduction_right_node}</p>
                                    <p className="text-lg mb-1 font-semibold">Today Balance </p>
                                    <p className="text-sm mb-4">{DashboardDetail.bleft_node} | {DashboardDetail.bright_node}</p>
                                    <p className="text-lg mb-1 font-semibold">Left Sponsored Associates : <span className="text-sm mb-4">{DashboardDetail.left_side_sponsored}</span> </p>
                                    <p className="text-lg mb-1 font-semibold">Right Sponsored Associates : <span className="text-sm mb-4"> {DashboardDetail.right_side_sponsored}</span></p>
                                </div>
                            ) }
                                  </>
                            ): <p className="text-center text-sm font-semibold">No Data Available</p>
                           }
                            <div className="mt-5">
                                <table className="w-full  hidden">
                                    <tbody>
                                    <tr className="bg-[#EEEEF0]">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-[#EEEEF0]">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-[#EEEEF0]">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>

                                    <tr className="bg-[#EEEEF0]">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-[#EEEEF0]">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-white border-b border-custom-border">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DashboardBottom;